import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth';
import { environment } from '@env/environment';
import { CourseEventInterface, CourseInterface, CoursePersonnel } from '@shared/interfaces/course';
import { UserInterface } from '@shared/interfaces/user-interface';
import { ddCalendarUtils } from '@shared/services/calendar.utils';
import { ddCourseUtils } from '@shared/services/course.utils';
import * as dayjs from 'dayjs';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CoursesService {

	public viewCourseSource = new BehaviorSubject<any>(null);
	public viewCourse$: Observable<any> = this.viewCourseSource.asObservable();

	public featuredCourseSource = new BehaviorSubject<any>(null);
	public featuredCourse$: Observable<any> = this.featuredCourseSource.asObservable();

	public coursesSource = new BehaviorSubject<any>(null);
	public courses$: Observable<any> = this.coursesSource.asObservable();

	public submissionsSource = new BehaviorSubject<any>(null);
	public submissions$: Observable<any> = this.submissionsSource.asObservable();

	public newestCoursesSource = new BehaviorSubject<any>(null);
	public newestCourses$: Observable<any> = this.newestCoursesSource.asObservable();

	public lastchanceCoursesSource = new BehaviorSubject<any>(null);
	public lastchanceCourses$: Observable<any> = this.lastchanceCoursesSource.asObservable();

	availableCategories: SelectItem[] = [];


	/**
	 * Emitts { head: this.head , course: this.course }
	 */
	public openBookingDialog: EventEmitter<any> = new EventEmitter<any>();
	userLastLogin: number|null;

	constructor(private authService: AuthenticationService, private http: HttpClient) {

		this.authService.currentUserSubject.subscribe((data) => {
			if(data === null) {
				this.viewCourseSource.next(null);
				this.featuredCourseSource.next(null);
				this.coursesSource.next(null);
				this.submissionsSource.next(null);
			}
		});

		this.userLastLogin = +localStorage.getItem('app.lastLogin');
	}

	getPersonnelDescField(person: CoursePersonnel)
	{
		if(person.CustomFields) {
			let field:any = person.CustomFields.find((i:any) => i.MetaType == 'personnelDesc');

			if(field !== undefined) {
				return field.CustomFieldValue;
			}
		}

		return '';
	}

	async getCourseByID(EventId: number, CourseTemplateId: number) {
		let courses: CourseInterface[];
		let getCourses = this.coursesSource.getValue();

		if (getCourses === null) {
			await this.fetchCourses().then((fetchCourses: CourseInterface[]) => courses = fetchCourses);
		} else {
			courses = getCourses;
		}

		let findCourse = await courses.find((i) => i.CourseTemplateId == CourseTemplateId);

		if(findCourse !== undefined) {
			findCourse.SingleEvent = findCourse.Events.find((e) => e.EventId == EventId);
		}

		//console.error('getCourseByID', findCourse);
		return findCourse;

		/*
		await this.coursesSource.subscribe(async (getCourses: CourseInterface[]) => {
			console.error('coursesSource', getCourses);
			if (getCourses === null) {
				await this.fetchCourses().then((fetchCourses: CourseInterface[]) => courses = fetchCourses);
			} else {
				courses = getCourses;
			}

			console.error('courses', courses);
		})*/
	}

	fetchCourseByID(courseID: number, headID: number) {
		let formData = new FormData();
		formData.append('subaction', 'getCourseByID');
		formData.append('CourseId', courseID.toString());

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	/**
	 * get courses
	 * @param view (heads, courses)
	 */
	fetchCourses(view: string = 'heads') {
		let getCourses = this.coursesSource.getValue();

		if (getCourses !== null) {
			return Promise.resolve(getCourses);
		}

		let formData = new FormData();
		formData.append('subaction', 'getCourses');
		formData.append('view', view);

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((courses: CourseInterface[]) => {

				courses.forEach((course: CourseInterface) => {

					course._TopCategoryName = course.Categories[0]?.CategoryName;
					course._FutureEvents = 0;
					course.Personnels = [];
					course._CourseTypeList = [];

					if(course._CourseTypeList.findIndex((t) => t == course.CourseType) === -1)
						course._CourseTypeList.push(course.CourseType);

					if (course.Events) {
						course.Events.forEach((e) => {

							e.HasStarted = ddCalendarUtils.hasStarted(e.StartDate);
							e.IsRunning = ddCalendarUtils.isRunning(e.StartDate, e.EndDate);
							e.HasPassed = ddCalendarUtils.hasPassed(e.HasStarted, e.IsRunning, e);
							e.IsFuture = ddCalendarUtils.isFuture(e.StartDate);
							e.IsSubmissionOpen = ddCourseUtils.isSubmissionOpen(e, course);//this._course['submission_open'] == 1 && this._course['spots_left'] > 0;
							e.IsSubmissionClosed = ddCourseUtils.isSubmissionClosed(e, course);//this._course['submission_open'] == 1 && this._course['spots_left'] > 0;

							/* Collect personnel! */
							if(e.Personnel) {
								e.Personnel.forEach((personel: CoursePersonnel) => {
									if (course.Personnels.findIndex((i) => i.PersonnelId == personel.PersonnelId) == -1)
										course.Personnels.push(personel);
								});
							}

							/*if(e.AddressName == 'Digitalt')
								course._IsDigital = true;
							if(e.AddressName != 'Digitalt')
								course._IsPhysical = true;*/

							if(course._CourseTypeList.findIndex((t) => t == e.City) === -1)
								course._CourseTypeList.push(e.City);

							if(!e.HasStarted)
								course._FutureEvents += 1;
						});
					}
				});

				this.coursesSource.next(courses);
				return courses;
			});
	}

	/**
	 * get latest courses
	 */
	fetchNewestCourses() {
		//console.error('fetchCourses');
		let formData = new FormData();
		formData.append('subaction', 'getNewestCourses');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.newestCoursesSource.next(data);
				return data;
			});
	}

	/**
	 * get latest courses
	 */
	fetchRecommendedCourses() {
		//console.error('fetchCourses');
		let formData = new FormData();
		formData.append('subaction', 'getRecommendedCourses');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.newestCoursesSource.next(data);
				return data;
			});
	}

	fetchLastChanceCourses() {
		//console.error('fetchCourses');
		let formData = new FormData();
		formData.append('subaction', 'getLastChanceCourses');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.lastchanceCoursesSource.next(data);
				return data;
			});
	}

	fetchMyCourseMaterial() {
		let formData = new FormData();
		formData.append('subaction', 'getMyCourseMaterial');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				//this.coursesSource.next(data);
				return data;
			});
	}

	fetchfeaturedCourse() {
		let formData = new FormData();
		formData.append('subaction', 'getFeaturedCourse');

		if(this.featuredCourseSource.getValue()) {
			return new Promise(resolve => {
				resolve(this.featuredCourseSource.getValue());
			});
		}

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.featuredCourseSource.next(data);

				return data;
			});
	}

	fetchLedaMedFramgång(reload: boolean = false) {
		let formData = new FormData();
		formData.append('subaction', 'getLedaMedFramgang');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				//this.submissionsSource.next(data);
				return data;
			});
	}

	fetchSubmissions(reload: boolean = false) {
		let formData = new FormData();
		formData.append('subaction', 'getSubmissions');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				this.submissionsSource.next(data);
				return data;
			});
	}

	getSubmissionStatus(EventId: number, employees: any[])
		{
		let formData = new FormData();
		formData.append('subaction', 'submissionStatus');
		formData.append('params', JSON.stringify(
			{
				'users' : employees.map((i) => i.PersonId),
				'eventId' : EventId.toString()
			}));

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	cancelSubmission(ParticipantId: number, PersonId: number) {
		let formData = new FormData();
		formData.append('subaction', 'cancelSubmission');
		formData.append('ParticipantId', ParticipantId.toString());
		formData.append('PersonId', PersonId.toString());

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((result: any) => {
				//if(!result.toastMessage.error) {
					//let users = this.employeesSource.getValue();
					//users.splice(users.findIndex(i => i.PersonId == userID), 1);
					//this.employeesSource.next(users)
				//}
				return result;
			});
	}

	cancelSubmissionOLD(submissionID: number, reason: string, cancelAllparts: boolean) {
		let formData = new FormData();
		formData.append('subaction', 'cancelSubmission');
		formData.append('submissionID', submissionID.toString());
		formData.append('reason', reason);
		formData.append('allParts', cancelAllparts ? '1' : '0');

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((result: any) => {
				//if(!result.toastMessage.error) {
					//let users = this.employeesSource.getValue();
					//users.splice(users.findIndex(i => i.PersonId == userID), 1);
					//this.employeesSource.next(users)
				//}
				return result;
			});
	}

	async sendSubmission(userID: number, course: CourseEventInterface)
	{
		let formData = new FormData();
		formData.append('subaction', 'addSubmissions');
		formData.append('params', JSON.stringify(
			{
				'p46_ca_course_registration_userID' : [userID],
				'eventId' : course.EventId
			}));

		return await this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	addUserToWaitingList(userID: number, courseID: number, startdate: number)
	{
		let formData = new FormData();
		formData.append('subaction', 'addUserToWaitingList');
		formData.append('userID', userID.toString());
		formData.append('courseID', courseID.toString());
		formData.append('startdate', startdate.toString());

		return this.http
			.post(environment.serverUrl, formData)
			.toPromise()
			.then((res) => res)
			.then((data) => {
				return data;
			});
	}

	/**
	 * getFutureEvents
	 * @param events
	 * @param showOnlyAvailable - Visa bara events som inte är stängda (utgått) eller är fullbokade
	 */
	getFutureEvents(events: CourseEventInterface[], showOnlyAvailable: boolean = false): CourseEventInterface[] {

		if(!showOnlyAvailable)
			return events.filter((e) => !e.HasPassed || e.IsRunning);
		else {
			return events.filter((e) => e.IsFuture && e.ParticipantNumberLeft > 0 && !e.IsSubmissionClosed);
		}

	}
}

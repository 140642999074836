export const MenuItems = [
	{label: 'Hem', icon: 'pi pi-fw pi-home', routerLink: '/hem', role: 'leader,employee', project: '*'},
	{label: 'Aktiviteter', icon: 'pi pi-fw pi-bookmark', routerLink: '/aktiviteter', role: 'leader,employee', project: '*'},
	{label: 'Boka möte', icon: 'pi pi-fw pi-calendar-plus', routerLink: '/boka/möte', role: 'leader', project:'reform'},
	//{label: 'Boka möte', icon: 'pi pi-fw pi-calendar-plus', routerLink: '/aktiviteter/visa/570043/0', role: 'leader', project:'reform'},
	{label: 'Mina aktiviteter', icon: 'pi pi-fw pi-calendar', routerLink: '/bokade', styleClass: 'test', role: 'leader,employee', project: '*'},
	//{label: 'Leda med framgång', icon: 'fa-solid fa-graduation-cap', routerLink: '/leda-med-framgång', styleClass: 'test', role: 'leader', project: 'reform'},
	{label: 'Boka möte med oss', icon: 'pi pi-fw pi-chart-bar', routerLink: '/boka/affärsutvecklare', role: 'courseleader', leaderType: 'affärsutvecklare'},
	{label: 'Min profil', icon: 'pi pi-fw pi-user', routerLink: '/profil', role: 'leader,employee', project: '*'},
	//{label: 'Profil stepwiz', icon: 'pi pi-fw pi-user-edit', routerLink: '/användarwizard'},
	//{label: 'Företag stepwiz', icon: 'pi pi-fw pi-user-edit', routerLink: '/företagswizard'},
	//{label: 'Företagsprofil', icon: 'pi pi-fw pi-briefcase', routerLink: '/företagsprofil', role: 'leader'},
	{label: 'Medarbetare', icon: 'pi pi-fw pi-users', routerLink: '/medarbetare', role: 'leader', project: '*'},
	{label: 'Analyser', icon: 'pi pi-fw pi-chart-bar', routerLink: '/analyser', role: '*', project: '*'},
	{label: 'Kommunikationskit för företagsledare', icon: 'pi pi-fw pi-directions', routerLink: '/kommunikations-kit', role: 'leader', project:'perfekt'},

	//{label: 'Behovskartläggning', icon: 'pi pi-fw pi-chart-bar', routerLink: '/analyser', role: 'leader'},
	//{label: 'Digital Mognadsanalys', icon: 'pi pi-fw pi-mobile', routerLink: '/analyser/visa/3', role: 'leader'},


	//{label: 'Kursmaterial', icon: 'pi pi-fw pi-file', routerLink: '/aktiviteter/kursmaterial', styleClass: 'test', role: 'leader,employee'},

	{label: 'Hem', icon: 'pi pi-fw pi-home', routerLink: '/kursledare/hem', role: 'courseleader'},
	{label: 'Mina aktiviteter', icon: 'pi pi-fw pi-user', routerLink: '/kursledare/aktiviteter', role: 'courseleader'},
	{label: 'Boka möte med oss', icon: 'pi pi-fw pi-chart-bar', routerLink: '/boka/affärsutvecklare', role: 'courseleader', leaderType: 'affärsutvecklare'},
	{label: 'Min profil', icon: 'pi pi-fw pi-user', routerLink: '/kursledare/profil', role: 'courseleader'},


	/**
	 * Superadmin
	 */
	{label: 'Aktiviteter', icon: 'pi pi-fw pi-user', routerLink: '/superadmin/activities', role: 'superadmin'},
	{label: 'Boka möte', icon: 'pi pi-fw pi-calendar', routerLink: '/superadmin/booking', role: 'superadmin'},
	{label: 'Bokningsresurser', icon: 'pi pi-fw pi-users', routerLink: '/superadmin/booking-leaders', role: 'superadmin'},
	{label: 'Närvarorapportering', icon: 'pi pi-fw pi-users', routerLink: '/superadmin/booking-report-attending', role: 'superadmin'},
];

export const MenuItemsSmall = [
	{label: 'Hållbart & inkluderande företagande', icon: '', routerLink: '/tips', styleClass: 'font-weight-normal'},
	{label: 'Frågor & svar', icon: '', routerLink: '/faq', styleClass: 'font-weight-normal'},
	{label: 'Kontakt', icon: '', routerLink: '/kontakt', styleClass: 'font-weight-normal'},
	{label: 'GDPR villkor', icon: '', routerLink: '/gdpr', styleClass: 'font-weight-normal'},
	{label: 'Att ingå i ett ESF-projekt', icon: '', routerLink: '/esf', styleClass: 'font-weight-normal'},
];
